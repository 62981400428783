import React, { useLayoutEffect, useRef, useState } from "react";
import { FiltersIcon } from "assets/icons";
import { Link, Popover, Typography } from "@mui/material";
import { usePopover } from "libs/hooks";
import { HStack } from "ui/atoms";
import { space } from "ui/vars";
import { PopoverFilters } from "./PopoverFilters";

export function TruncateFilters({
  children,
  clearAll,
  hideClearAll,
  dataId = undefined,
  fixedElements = 200,
  showPrefix = true,
}: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [cutBefore, setCutBefore] = useState(0);

  const childrenArray = React.Children.toArray(children).filter((c) => !!c);
  const [comp, actions] = usePopover((api) => (
    <Popover {...api}>
      <PopoverFilters>
        {(childrenArray as React.ReactElement[])
          .slice(0, cutBefore)
          .map((el) => React.cloneElement(el, { animateMount: false }))}
        <Link color="textSecondary" style={{ lineHeight: 1 }} onClick={clearAll}>
          Clear All
        </Link>
      </PopoverFilters>
    </Popover>
  ));
  useLayoutEffect(() => {
    if (actions.open && cutBefore > childrenArray.length) {
      actions.onClose();
    }
  }, [childrenArray.length, actions.open]);

  // filters label + clear all + +n button
  // todo watch window resize
  useLayoutEffect(() => {
    const node = ref.current!;
    let availableWidth = node.clientWidth - fixedElements;
    const { childNodes } = node;
    let i;
    for (i = childNodes.length - 1; i >= 0; i--) {
      const element = childNodes[i] as HTMLDivElement;
      if (element) {
        const style = window.getComputedStyle(childNodes[i] as HTMLDivElement);
        const minWidth = parseInt(style.minWidth || "0");
        const itemSize = minWidth || element.clientWidth || 0;
        availableWidth -= itemSize;
        if (availableWidth < 0) {
          break;
        }
      }
    }
    setCutBefore(i);
  });
  const filtersNode = showPrefix && (
    <HStack space="1" align="center" style={{ marginRight: space["1"] }}>
      <FiltersIcon style={{ fontSize: 20, color: "#545454" }} />
      <Typography
        style={{ fontWeight: 400, textTransform: "capitalize" }}
        variant="h6"
        data-fs="unmask"
      >
        Filters applied:
      </Typography>
    </HStack>
  );
  return (
    <>
      <HStack
        space="1"
        align="center"
        style={{ height: 0, margin: 0, overflowY: "hidden" }}
        ref={ref}
      >
        {filtersNode}
        {children}
      </HStack>
      <HStack space="1" align="center" style={{ height: 30 }} data-testid={dataId}>
        {filtersNode}
        {cutBefore > 0 && (
          <Link color="textPrimary" onClick={(e: any) => actions.onOpen(e.currentTarget)}>
            + {cutBefore} more
          </Link>
        )}
        {cutBefore > 0 ? childrenArray.slice(cutBefore) : childrenArray}

        {cutBefore > 0 && comp}
        <Link
          onClick={clearAll}
          color="textSecondary"
          style={{
            display: hideClearAll ? "none" : "block",
            lineHeight: 1.5,
            marginLeft: space[3],
            marginTop: space[1],
            flexShrink: 0,
          }}
          data-fs="unmask"
        >
          Clear All
        </Link>
      </HStack>
    </>
  );
}
