import React from "react";
import { Stack } from "libs/layouts";
import { css } from "ui/css";
import { Box } from "ui/atoms/Box";
import { space } from "ui/vars";

const stackStyles = css({
  ".filter-chip": {
    "height": "auto !important",
    "width": "fit-content",
    "> span": {
      whiteSpace: "normal",
      padding: "4px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    ".filter-label": { flexWrap: "wrap" },
  },
});

export function PopoverFilters({ children }: any) {
  return (
    <Box
      sx={{
        paddingTop: space[4],
        paddingBottom: space[4],
        paddingLeft: space[3],
        paddingRight: space[3],
      }}
      style={{ width: 400 }}
    >
      <Stack space={3} className={stackStyles().className}>
        {children}
      </Stack>
    </Box>
  );
}
