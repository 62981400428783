import React from "react";
import { Typography } from "@mui/material";
import { jsxJoin } from "libs/data-utils";
import { HStack } from "libs/layouts";

export function FilterLabel({ prefix, items, style }: any) {
  return (
    <HStack
      align="center"
      space={1}
      className="filter-label"
      as={Typography}
      variant="body2"
      style={{ fontSize: 13, display: "inline-flex", ...style }}
    >
      <span>{prefix}:</span>
      {jsxJoin(
        items.map((el: any) => (typeof el === "string" ? <span>{el}</span> : el)),
        () => (
          <b> OR </b>
        )
      )}
    </HStack>
  );
}
