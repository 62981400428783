export enum Feature {
  ANALYTICS_CUSTOMIZABLE_CHARTS = "ANALYTICS_CUSTOMIZABLE_CHARTS",
  ANALYTICS_CUSTOMIZABLE_FILTERS = "ANALYTICS_CUSTOMIZABLE_FILTERS",
  ANALYTICS_DASHBOARD_ALERTS = "ANALYTICS_DASHBOARD_ALERTS",
  ANALYTICS_DASHBOARD_AUTO_REFRESH = "ANALYTICS_DASHBOARD_AUTO_REFRESH",
  ANALYTICS_MAX_CONCURRENT_CHARTS_LOADING = "ANALYTICS_MAX_CONCURRENT_CHARTS_LOADING",
  BLOCKING = "BLOCKING",
  CONSOLE_SUPPORTED_ENDPOINT_FIELDS = "CONSOLE_SUPPORTED_ENDPOINT_FIELDS",
  DASHBOARD_TRENDS_OVER_TIME_WIDGET = "DASHBOARD_TRENDS_OVER_TIME_WIDGET",
  DATAFLOWS_RETENTION_DAYS = "DATAFLOWS_RETENTION_DAYS",
  ANALYTICS_RETENTION_DAYS = "ANALYTICS_RETENTION_DAYS",
  HARD_LIMIT_EDGES = "HARD_LIMIT_EDGES",
  NUM_PRELOADED_EDGES = "NUM_PRELOADED_EDGES",
  DIRECTORY_INTEGRATION_CEL_EXPRESSIONS = "DIRECTORY_INTEGRATION_CEL_EXPRESSIONS",
  ENABLE_EXTERNAL_CONTENT = "ENABLE_EXTERNAL_CONTENT",
  ENABLE_READONLY_REMOTE_CONFIG_MODE = "ENABLE_READONLY_REMOTE_CONFIG_MODE",
  ENABLE_SCREENSHOTS = "ENABLE_SCREENSHOTS",
  ENABLE_SILENT_SCREENSHOTS = "ENABLE_SILENT_SCREENSHOTS",
  ENFORCE_2FA_UI = "ENFORCE_2FA_UI",
  INSIDER_RISK_MANAGEMENT_TABLE = "INSIDER_RISK_MANAGEMENT_TABLE",
  IRM_USER_GROUPS_STRING_ARRAY = "IRM_USER_GROUPS_STRING_ARRAY",
  UI_LEGACY_AWS_VISIBLE_FOR_CREATE = "UI_LEGACY_AWS_VISIBLE_FOR_CREATE",
  UPDATE_POLICY_LTS = "UPDATE_POLICY_LTS",
  RO_LOW_PERFORMANCE_INDICATOR = "RO_LOW_PERFORMANCE_INDICATOR",
  USER_WIDGET_DIRECTORY_DETAILS_ENABLED = "USER_WIDGET_DIRECTORY_DETAILS_ENABLED",
  DATAFLOWS_ALLOW_NON_PRECOMPUTED = "DATAFLOWS_ALLOW_NON_PRECOMPUTED",
  RO_USER_DIRECTORY_RULES = "RO_USER_DIRECTORY_RULES",
  ENABLE_GLOBAL_SEARCH = "ENABLE_GLOBAL_SEARCH",
  CONTENT_BASED_RBAC = "CONTENT_BASED_RBAC",
  INLINE_CONTENT_PREVIEW = "INLINE_CONTENT_PREVIEW",
  MAX_DOMAINS_PER_CLOUD_SENSOR = "MAX_DOMAINS_PER_CLOUD_SENSOR",
  DEPLOYMENT_GROUP_BASED_ON_DIRECTORY_USER_GROUP_ENABLED = "DEPLOYMENT_GROUP_BASED_ON_DIRECTORY_USER_GROUP_ENABLED",
  MATCHING_LISTS_ENABLED = "MATCHING_LISTS_ENABLED",
  LANDING_PAGE = "LANDING_PAGE",
  IRM_USER_RISK_GROUP_CREATION_DOCS_LINK = "IRM_USER_RISK_GROUP_CREATION_DOCS_LINK",
  IRM_USER_RISK_GROUP_CLEAR_RISK_SCORE_DOCS_LINK = "IRM_USER_RISK_GROUP_CLEAR_RISK_SCORE_DOCS_LINK",
  VA_NAVIGATION_OPTIONS = "VA_NAVIGATION_OPTIONS",
  EDM_RULES_ENABLED = "EDM_RULES_ENABLED",
  ABAC_ALLOW_RELATED_EVENTS = "ABAC_ALLOW_RELATED_EVENTS",
  RO_EVENT_COUNTS = "RO_EVENT_COUNTS",
  LOCATIONS_WIDGET_V1_5 = "LOCATIONS_WIDGET_V1_5",
  SHARABLE_EVENTS_DETAILS = "SHARABLE_EVENTS_DETAILS",
  ENABLED_THIRD_PARTY_SERVICES = "ENABLED_THIRD_PARTY_SERVICES",
  ENABLE_DIRECTORY_USER_FETCHING = "ENABLE_DIRECTORY_USER_FETCHING",
  AI_INCIDENT_DETECTION = "AI_INCIDENT_DETECTION",
  INCIDENT_RESOLUTION_FEEDBACK = "INCIDENT_RESOLUTION_FEEDBACK",
  FIREBASE_AUTH_DELAY = "FIREBASE_AUTH_DELAY",
  ABAC_ALLOW_CHANGE_POLICY_ASSIGNMENT_TYPE = "ABAC_ALLOW_CHANGE_POLICY_ASSIGNMENT_TYPE",
  CONSOLE_LINUX_SUPPORT = "CONSOLE_LINUX_SUPPORT",
}

export type Features = {
  [key in Exclude<
    Feature,
    | Feature.CONSOLE_SUPPORTED_ENDPOINT_FIELDS
    | Feature.DATAFLOWS_RETENTION_DAYS
    | Feature.HARD_LIMIT_EDGES
    | Feature.NUM_PRELOADED_EDGES
    | Feature.ANALYTICS_MAX_CONCURRENT_CHARTS_LOADING
    | Feature.ENABLE_READONLY_REMOTE_CONFIG_MODE
    | Feature.MAX_DOMAINS_PER_CLOUD_SENSOR
    | Feature.IRM_USER_RISK_GROUP_CREATION_DOCS_LINK
    | Feature.IRM_USER_RISK_GROUP_CLEAR_RISK_SCORE_DOCS_LINK
    | Feature.VA_NAVIGATION_OPTIONS
    | Feature.ENABLED_THIRD_PARTY_SERVICES
    | Feature.FIREBASE_AUTH_DELAY
  >]?: boolean;
} & {
  ENABLED_THIRD_PARTY_SERVICES?: Record<"pendo", boolean>;
  // Non-boolean feature flags
  [Feature.CONSOLE_SUPPORTED_ENDPOINT_FIELDS]?: {
    allowed?: string[];
    all_allowed?: boolean;
    automatic?: boolean;
  };
  [Feature.DATAFLOWS_RETENTION_DAYS]?: number;
  [Feature.ANALYTICS_MAX_CONCURRENT_CHARTS_LOADING]?: number;
  [Feature.HARD_LIMIT_EDGES]?: number;
  [Feature.NUM_PRELOADED_EDGES]?: number;
  [Feature.MAX_DOMAINS_PER_CLOUD_SENSOR]: number;
  [Feature.FIREBASE_AUTH_DELAY]?: number;
  // "reader" | "writer" | "";
  [Feature.ENABLE_READONLY_REMOTE_CONFIG_MODE]?: string;
  [Feature.IRM_USER_RISK_GROUP_CREATION_DOCS_LINK]?: string;
  [Feature.IRM_USER_RISK_GROUP_CLEAR_RISK_SCORE_DOCS_LINK]?: string;
  [Feature.VA_NAVIGATION_OPTIONS]?: string[];
};

type RequiredDevFeatures = Required<Omit<Features, Feature.FIREBASE_AUTH_DELAY>>;
type OptionalDevFeatures = {
  [Feature.FIREBASE_AUTH_DELAY]?: number;
};

/*
  Use this constant to configure local env with features.
  On production this will be empty meaning all features disabled by default.
*/
const devEnvFeatures: RequiredDevFeatures & OptionalDevFeatures = {
  ANALYTICS_CUSTOMIZABLE_CHARTS: true,
  ANALYTICS_CUSTOMIZABLE_FILTERS: true,
  ANALYTICS_DASHBOARD_ALERTS: true,
  ANALYTICS_DASHBOARD_AUTO_REFRESH: true,
  ANALYTICS_MAX_CONCURRENT_CHARTS_LOADING: 2,
  BLOCKING: true,
  CONSOLE_SUPPORTED_ENDPOINT_FIELDS: { allowed: [] as string[] },
  DASHBOARD_TRENDS_OVER_TIME_WIDGET: true,
  DATAFLOWS_RETENTION_DAYS: 30,
  DIRECTORY_INTEGRATION_CEL_EXPRESSIONS: true,
  ENABLE_SILENT_SCREENSHOTS: true,
  ENABLE_EXTERNAL_CONTENT: true,
  ENABLE_READONLY_REMOTE_CONFIG_MODE: "reader",
  ENABLE_SCREENSHOTS: true,
  ENFORCE_2FA_UI: true,
  INSIDER_RISK_MANAGEMENT_TABLE: true,
  IRM_USER_GROUPS_STRING_ARRAY: false,
  UI_LEGACY_AWS_VISIBLE_FOR_CREATE: true,
  UPDATE_POLICY_LTS: true,
  HARD_LIMIT_EDGES: 1000,
  NUM_PRELOADED_EDGES: 1000,
  RO_LOW_PERFORMANCE_INDICATOR: true,
  ANALYTICS_RETENTION_DAYS: true,
  USER_WIDGET_DIRECTORY_DETAILS_ENABLED: true,
  DATAFLOWS_ALLOW_NON_PRECOMPUTED: true,
  RO_USER_DIRECTORY_RULES: true,
  ENABLE_GLOBAL_SEARCH: true,
  CONTENT_BASED_RBAC: true,
  INLINE_CONTENT_PREVIEW: true,
  MAX_DOMAINS_PER_CLOUD_SENSOR: 2,
  DEPLOYMENT_GROUP_BASED_ON_DIRECTORY_USER_GROUP_ENABLED: true,
  MATCHING_LISTS_ENABLED: true,
  LANDING_PAGE: true,
  IRM_USER_RISK_GROUP_CREATION_DOCS_LINK: "https://docs.cyberhaven.io/docs/user-risk-group",
  IRM_USER_RISK_GROUP_CLEAR_RISK_SCORE_DOCS_LINK: "",
  VA_NAVIGATION_OPTIONS: [],
  EDM_RULES_ENABLED: true,
  ABAC_ALLOW_RELATED_EVENTS: false,
  RO_EVENT_COUNTS: true,
  LOCATIONS_WIDGET_V1_5: true,
  SHARABLE_EVENTS_DETAILS: true,
  ENABLED_THIRD_PARTY_SERVICES: {
    pendo: false,
  },
  ENABLE_DIRECTORY_USER_FETCHING: true,
  AI_INCIDENT_DETECTION: true,
  INCIDENT_RESOLUTION_FEEDBACK: true,
  ABAC_ALLOW_CHANGE_POLICY_ASSIGNMENT_TYPE: false,
  CONSOLE_LINUX_SUPPORT: true,
};

// True for features that already rolled out to everyone
const prodEnvFallbackFeatures: Features = {
  BLOCKING: true,
  DATAFLOWS_RETENTION_DAYS: 90,
  ANALYTICS_MAX_CONCURRENT_CHARTS_LOADING: 3,
  ENABLE_READONLY_REMOTE_CONFIG_MODE: "",
  MAX_DOMAINS_PER_CLOUD_SENSOR: 1,
};

export const initialFeatures: Features =
  process.env.NODE_ENV !== "production" ? devEnvFeatures : prodEnvFallbackFeatures;
